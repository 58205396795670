import React, {useState} from "react";
import {Dialog, DialogFooter} from "../../../components/dialog";
import {PanelContent} from "../../../components/panel";
import {Button} from "../../../components/button";
import {OrdemServicoFluxoDropdown} from "../../../components/dropdown/ordemServicoFluxoDropdown";
import {useDialog} from "../../../utils/dialogContext";
import {ConfirmDialogV2} from "../../../components/confirmdialog";
import {ordemServicoService} from "../../../service/ordemServicoService";
import {ordemServicoItemService} from "../../../service/ordemServicoItemService";
import {ProgressDialog} from "../../../components/progressdialog/ProgressDialog";
import {UsuarioAutoComplete} from "../../../components/autocomplete/usuarioAutoComplete";
import {ServicoAutoComplete} from "../../../components/autocomplete/servicoAutoComplete";
import {SelectButton} from "../../../components/selectbutton";

export function AbrirOrdensServico({projeto, equipamentos, onClose}) {
	const {showDialog} = useDialog();
	const [params, setParams] = useState({
		operacao: null,
		fluxo: null,
		responsavel: null,
		agrupar: true
	});
	const [visible, setVisible] = useState(true);
	function handleClose() {
		if (onClose) {
			onClose();
		}
		setVisible(false);
	}
	function criarOrdemServico(equipamento) {
		return {
			...ordemServicoService.criar(),
			operacao: params.fluxo.operacao,
			contrato: projeto,
			fluxo: `${params.fluxo.descricao} (v${params.fluxo.versao})`,
			etapas: params.fluxo.etapas,
			etapa: params.fluxo.etapas[0],
			cliente: equipamento?.cliente || projeto.cliente,
			clienteFaturamento: projeto.cliente,
			condicaoPagamento: projeto.condicaoPagamento || projeto.cliente.vwCliente.condicaoPagamento,
			responsavel: params.responsavel,
			acompanhamentos: [{
				...ordemServicoService.criarAcompanhamento(),
				etapa: params.fluxo.etapas[0],
				responsavel: params.responsavel,
				operacao: params.operacao
			}],
			postBack: true,
			notaFiscal: null,
			tipo: "PROJETO"
		};
	}
	function confirmarOrdensServico() {
		showDialog(
			<ConfirmDialogV2
				message={`Confirma a criação das ordens de serviço para os ${equipamentos.length} equipamentos?`} onYes={async () => {
					showDialog(<ProgressDialog onProgress={async setProgresso => {
						let progresso = 0;
						const total = equipamentos.length * 2;
						if (params.agrupar) {
							ordemServico = criarOrdemServico();
							ordemServico = await ordemServicoService.salvar(ordemServico);
							setProgresso(++progresso / total);
						}
						let ordemServico;
						let sequencia = 1;
						for (const equipamento of equipamentos) {
							if (!params.agrupar) {
								ordemServico = criarOrdemServico(equipamento);
								ordemServico = await ordemServicoService.salvar(ordemServico);
								sequencia = 1;
							}
							setProgresso(++progresso / total);
							let item = {
								...ordemServicoItemService.criar(),
								sequencia: sequencia++,
								equipamento,
								ordemServico: {id: ordemServico.id}
							}
							if (params.servico?.id) {
								item.servicos = [{
									sequencia: 1,
									servico: params.servico
								}];
							}
							await ordemServicoItemService.salvar(item);
							setProgresso(++progresso / total);
						}
					}} onFinish={handleClose}/>);
				}}
			/>
		);
	}
	function handleChange(e) {
		setParams(prevParams => ({...prevParams, [e.name]: e.value}));
	}
	return (
		<Dialog header="Abrir Ordens de Serviço" style={{width: "750px"}} visible={visible} onHide={handleClose}>
			<PanelContent>
				<OrdemServicoFluxoDropdown
					name="fluxo"
					value={params.fluxo}
					onChange={handleChange}
					col={8}
				/>
				<SelectButton
					col={4}
					name="agrupar"
					value={params.agrupar}
					onChange={handleChange}
					options={[
						{label: "Agrupar", value: true},
						{label: "Não Agrupar", value: false},
					]}
					label="Agrupamento"
				/>
				<UsuarioAutoComplete
					col={12}
					name="responsavel"
					value={params.responsavel}
					onChange={handleChange}
					label="Responsável"
				/>
				<ServicoAutoComplete
					col={12}
					name="servico"
					value={params.servico}
					onChange={handleChange}
					label="Serviço"
				/>
			</PanelContent>
			<DialogFooter>
				<Button label="Confirmar" icon="fas fa-check" success onClick={confirmarOrdensServico}/>
				<Button label="Cancelar" icon="fas fa-times" secondary onClick={handleClose}/>
			</DialogFooter>
		</Dialog>
	);
}
